<script setup>
	const props = defineProps({
		data: {
			type: Object,
			default: () => ({}),
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});

	const { data } = toRefs(props);
	const member = computed(() => data.value?.content?.data);
</script>

<template>
	<section class="block block-team-member-detail-header bg-gray-100" tabindex="-1">
		<div class="container">
			<MessImage v-if="member?.image" :src="member?.image" :alt="member?.imageAlt" />

			<header>
				<div class="list-wrapper">
					<ul v-if="member?.departmentOrCategory?.length" class="pill-list">
						<li v-for="(item, index) in member?.departmentOrCategory" :key="index" class="pill-wrapper">
							<ProjectPill :text="item" bgColor="var(--gray-100)" />
						</li>
					</ul>
				</div>

				<ProjectTitle v-if="member?.name" :title="member?.name" tag="h1" font="h3" />

				<MessHtml v-if="member?.title" :html="member?.title" tag="p" class="h5 title" :key="member.title + 'p-header'" />

				<MessHtml v-if="member?.bio" :html="member?.bio" tag="div" class="body-lg" :key="member.bio + 'p-header'" />

				<MessLink v-if="member?.linkedin" :href="member?.linkedin" target="_blank" class="linkedin-link">
					<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0_1516_1440)">
							<path d="M3.38641 4.93359H0.25V14.9338H3.38641V4.93359Z" fill="currentColor" />
							<path
								d="M11.8642 4.71065C11.7457 4.69764 11.6243 4.68825 11.5007 4.68319C9.74504 4.61166 8.75232 5.65352 8.40985 6.09786C8.31881 6.21708 8.27329 6.29366 8.27329 6.29366V4.95558H5.27344V14.9558H8.40985V10.2573C8.40985 9.57891 8.3571 8.85351 8.69668 8.23721C8.98424 7.71628 9.50373 7.45546 10.0918 7.45546C11.8237 7.45546 11.8649 9.02258 11.8649 9.17142V15.0013H15.0013V8.47853C15.0013 6.2467 13.867 4.92668 11.8649 4.71065H11.8642Z"
								fill="currentColor"
							/>
							<path
								d="M1.81855 3.63711C2.82291 3.63711 3.63711 2.82291 3.63711 1.81855C3.63711 0.814194 2.82291 0 1.81855 0C0.814194 0 0 0.814194 0 1.81855C0 2.82291 0.814194 3.63711 1.81855 3.63711Z"
								fill="currentColor"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1516_1440">
								<rect width="15" height="15" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</MessLink>
			</header>
		</div>
	</section>
</template>

<style lang="scss">
	.block-team-member-detail-header {
		.container {
			max-width: 1150px;

			@media (min-width: $mobile) {
				display: flex;
				align-items: flex-start;
			}
		}

		.mess-image {
			aspect-ratio: 1;
			width: 100%;

			@media (min-width: $mobile) {
				max-width: 440px;
			}
		}

		header {
			width: 100%;
			padding: 30px;
			background-color: var(--white);

			@media (min-width: $mobile) {
				max-width: 690px;
				margin-top: 72px;
				padding: 48px;
			}

			.list-wrapper {
				margin-bottom: 20px;

				.pill-list {
					display: flex;
					flex-wrap: wrap;
					margin: -5px;

					.pill-wrapper {
						margin: 5px;
					}
				}
			}

			h1 {
				margin-bottom: 15px;
			}

			.title {
				margin-bottom: 30px;
			}

			.body-lg {
				margin-bottom: 30px;
			}

			.linkedin-link {
				display: flex;
				justify-content: center;
				align-items: center;

				width: 41px;
				height: 41px;
				background-color: var(--gray-100);
				border-radius: 100%;
			}
		}
	}
</style>
